import { ARTICLE_TYPE, FILE_PRE_HOST_GZ, PosterType, imageTransform } from '@qcwp/common'
import type { PosterContext, PosterJson } from '@qcwp/common'

import type { LineModifyJsonOptions, LineModifyPathsFn } from './types'
import type { DailyEssenceFormatData } from '~~/src/logic/article/daily'

/**
 * 加载字体文件
 * @param fonts 字体url Record<string, string>
 */
export async function loadFontFace(fonts: Record<string, string>) {
  const promises = []
  for (const [key, value] of Object.entries(fonts)) {
    // FontFace only works in browsers
    const font = new FontFace(key, `url(${value})`)
    promises.push(font.load())
  }

  try {
    const fonts = await Promise.all(promises)
    fonts.forEach(font => document.fonts.add(font))
  }
  catch {}
}

export function verticalAlgin(
  json: PosterJson[],
  totalHeight: number,
  type: 'top' | 'bottom' | 'middle' | 'space-around' | 'space-between' | 'space-evenly' = 'middle',
) {
  const height = json.reduce((prev, curr) => prev + curr.height, 0)
  let gap = 0
  switch (type) {
    case 'top':
      gap = 0
      break
    case 'bottom':
      gap = totalHeight - height
      break
    case 'middle':
      gap = (totalHeight - height) / 2
      break
    case 'space-around':
      gap = (totalHeight - height) / (json.length + 1)
      break
    case 'space-between':
      gap = (totalHeight - height) / (json.length - 1)
      break
    case 'space-evenly':
      gap = (totalHeight - height) / (json.length + 1)
      break
    default:
      gap = (totalHeight - height) / 2
      break
  }
  json.forEach((item) => {
    item.y += gap
  })

  return json
}
/**
 *
 * @param context
 * @param positions
 * @param colors
 *
 * @example const g = createLinearGradient(poster.context, dpiScale([
    container.startX + offsetX,
    container.startY + offsetY,
    container.startX + offsetX + container.width,
    container.startY + offsetY + needHeight,
  ], poster.DPI) as [number, number, number, number], [[0, '#93c5fd'], [1, '#fca5a5']])

 */
export function createLinearGradient(
  context: CanvasRenderingContext2D,
  positions: [number, number, number, number],
  colors: [number, string][],
) {
  const g = context.createLinearGradient(...positions)
  colors.forEach((item) => {
    g.addColorStop(item[0], item[1])
  })
  return g
}
export function lineModifyJsonHandler(
  context: PosterContext,
  options: LineModifyJsonOptions,
  pathsHandler: LineModifyPathsFn,
): PosterJson {
  const { canvasContext, dpi } = context
  const {
    contentX,
    contentY,
    contentWidth,
    contentHeight,
    offsetX = 13,
    offsetY = 10,
    lineXLength = 0,
    lineYLength = 0,
    lineWidth = 5,
    color = '#fca5a5',
    isOffsetXContent = false,
    isOffsetYContent = false,
  } = options
  const x = contentX - getOffset(offsetX, 0) + (isOffsetXContent ? getOffset(offsetX, 0) : 0)
  const y = contentY - getOffset(offsetY, 0) - lineWidth / 2 + (isOffsetYContent ? getOffset(offsetY, 0) : 0)
  const width = contentWidth + getOffset(offsetX)
  const height = contentHeight + getOffset(offsetY) + lineWidth

  const lineContext = { canvasContext, dpi, x, y, width, height, lineXLength, lineYLength, lineWidth }

  return {
    type: PosterType.line,
    x,
    y,
    paths: pathsHandler(lineContext),
    lineWidth,
    width,
    color: typeof color === 'function' ? color(lineContext) : color,
    height,
  }
}
export function dpiScale(value: number[], dpi: number): number[]
export function dpiScale(value: number, dpi: number): number
export function dpiScale(value: number | number[], dpi: number) {
  if (Array.isArray(value))
    return value.map(item => item * dpi)
  else
    return value * dpi
}

export function reduceOffset(offset: number | number[], multiple = 1) {
  if (Array.isArray(offset))
    return offset.reduce((prev, curr) => prev + curr, 0)
  else
    return offset * multiple
}
export function getOffset(offset: number | number[] = 0, index = -1) {
  let r = 0
  if (Array.isArray(offset))
    r = index === -1 ? reduceOffset(offset) : offset[index]
  else
    r = index === -1 ? offset * 2 : offset
  return r
}

export function getContentUrl(v: DailyEssenceFormatData['articles'][number], isAddHost = true) {
  const host = isAddHost
    ? process.env.NODE_ENV === 'development' ? 'https://www.qcwp.com' : window.location.origin
    : ''
  if (v.articleType === ARTICLE_TYPE.PICTURE)
    return `${host}/news/${v.dataRefId}`
  else if (v.articleType === ARTICLE_TYPE.VIDEO)
    return `${host}/video/${v.dataRefId}`
  else if (v.articleType === ARTICLE_TYPE.LIVE)
    return `${host}/live/${v.dataRefId}`
  else
    return `${host}/news/${v.dataRefId}`
}
export function formatOptions(options: DailyEssenceFormatData) {
  const imageWidth = 750
  const imageHeight = Math.floor(imageWidth * 0.5625)

  const newOptions = { ...options }
  newOptions.articles = options.articles.map((item) => {
    return {
      ...item,
      dataRefImgs: item.essenceColumn === '2'
        ? item.dataRefImgs.map(img => imageTransform(img, { width: Math.floor(imageWidth / 3), height: Math.floor(imageHeight / 3), format: 'webp', host: FILE_PRE_HOST_GZ }))
        : item.dataRefImgs.map(img => imageTransform(img, { width: imageWidth, height: imageHeight, format: 'webp', host: FILE_PRE_HOST_GZ })),
      mediaUserVOList: item.mediaUserVOList.map(v => ({
        ...v,
        mediaAvatar: imageTransform(v.mediaAvatar, { width: 100, height: 100, format: 'webp', host: FILE_PRE_HOST_GZ }),
      })),
    }
  })
  return newOptions
}

export function getTestDriveText(articles: DailyEssenceFormatData['articles']) {
  const types = Array.from(new Set(articles.map(v => v.articleType)))
  const texts = []
  if (types.includes(ARTICLE_TYPE.PICTURE))
    texts.push('试驾')
  if (types.includes(ARTICLE_TYPE.VIDEO))
    texts.push('视频')
  if (types.includes(ARTICLE_TYPE.LIVE))
    texts.push('直播')
  return texts.length ? texts.join(' & ') : '测评试驾'
}

export function iosOffset(dpi: number, json: PosterJson[]) {
  if (!useDevice?.().isIos)
    return json
  const offset = 3 * dpi
  json.forEach((item) => {
    if ([PosterType.text, PosterType.textEllipsis].includes(item.type))
      item.y -= offset
  })
  return json
}
