<script setup lang='ts'>
import { userPhoneLogin } from '~~/src/pages/login/hooks/phoneLogin'
import { pxToRem } from '~~/src/common/theme/naive'
import { imgCodeApi } from '~/server/modules/auth'

const {
  phoneForm,
  phoneRules,
  phoneLogin,
} = userPhoneLogin()
const imgCodeData = ref({ img: '', uuid: '' })

async function getImageCode() {
  const res = await imgCodeApi()
  phoneForm.value.code = ''
  imgCodeData.value = res.data
}

onMounted(() => {
  getImageCode()
})
</script>

<template>
  <van-form class="phoneForm" validate-trigger="onSubmit" :label-width="`${pxToRem(50)}rem`" @submit="phoneLogin">
    <van-cell-group inset>
      <van-field
        v-model="phoneForm.loginAccountOrPhone"
        name="loginAccountOrPhone"
        label="手机号"
        placeholder="请输入手机号"
        :rules="phoneRules.loginAccountOrPhone"
      />

      <van-field
        v-model="phoneForm.code"
        name="code"
        label="图形码"
        placeholder="请输入图形码"
        :rules="phoneRules.code"
      >
        <template #button>
          <img :src="`data:image/png;base64,${imgCodeData.img}`" style="width:90px" @click="getImageCode">
        </template>
      </van-field>
      <van-field
        v-model="phoneForm.verifCode"
        name="verifCode"
        label="验证码"
        placeholder="请输入验证码"
        :rules="phoneRules.verifCode"
      >
        <template #button>
          <utils-verification :get-img-code="getImageCode" :uuid="imgCodeData.uuid" :code="phoneForm.code" :phone="phoneForm.loginAccountOrPhone" :seconds="60" />
        </template>
      </van-field>
    </van-cell-group>
    <div class="m-4">
      <van-button round block type="primary" native-type="submit">
        登录
      </van-button>
    </div>
  </van-form>
</template>

<style lang="scss" scoped>
.phoneForm {
  :deep(.van-field) {
    --uno: text-3.5 leading-11;

    .van-field__error-message {
      --uno: leading-normal;
    }

    .van-field__button {
      --uno: leading-normal;
    }
  }

  :deep(.van-button) {
    --uno: leading-10 text-3.5;
  }
}
</style>
